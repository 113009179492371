import React from 'react';
import {
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import { 
  Backdrop,
  CircularProgress
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#eb5505"
    }
  }
})

const strictModeTheme = unstable_createMuiStrictModeTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        zIndex: 1
      }
    }
  }
});

const Loading: React.FC<{isVisible:boolean}> = ({isVisible}) => { 
  return (
    <ThemeProvider theme={strictModeTheme}>
      <Backdrop open={isVisible}>
        <ThemeProvider theme={theme}>
          <CircularProgress color="primary" />
        </ThemeProvider>
      </Backdrop>
    </ThemeProvider>
  )
} 

export default Loading;