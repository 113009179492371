import React from 'react';
import {
  Box,
  Typography
 } from '@material-ui/core/';

interface Props {
  invalidMessage: string;
}

class Invalid extends React.Component<Props, {}> {
  render() {
    return (
      <Box textAlign="center" m="5rem auto">
        <Typography variant="body1">
          {this.props.invalidMessage}
        </Typography>
      </Box>
    );
  }
}

export default Invalid;