import React from 'react';
import {
  Typography,
  Divider,
  Box,
} from '@material-ui/core';

class Complete extends React.Component {
  render() {
    return (
      <>
        <Typography variant="h6" gutterBottom>回答完了</Typography>
        <Divider />
        <Box mt="1rem" mb="15rem">
          <Typography variant="body1">
            ご回答ありがとうございます。
          </Typography>
          <Typography variant="body1">
            フィルタリングサービスのご利用状況について確認しました。
          </Typography>
        </Box>
      </>
    );
  }
}

export default Complete;