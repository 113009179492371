import React from 'react';
import axios from 'axios';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Top from './components/pages/Top';
import Questionnaire from './components/pages/Questionnaire';
import Complete from './components/pages/Complete';
import Loading from './components/pages/Loading';
import Invalid from './components/pages/Invalid';
import {
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import { 
  Typography, 
  Box
} from '@material-ui/core';
import './components/pages/style.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#eb5505"
    }
  },
  typography: {
    h6: {
      fontSize: "1.2rem",
      fontWeight: 700,
      borderLeft: "4px solid #eb5505",
      paddingLeft: "1rem"
    },
    button: {
      fontWeight: "bold"
    },
    caption: {
      fontSize: ".625rem"
    }
  }
});

interface State {
  isLoading: boolean,
  id: string,
  isValid?: boolean,
  isAnswered?: boolean,
  isIE: boolean
}

class App extends React.Component<{}, State> {
  state:State = {
    isLoading: true,
    id: '',
    isValid: undefined,
    isAnswered: undefined,
    isIE: false
  }

  componentDidMount(){
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
      this.setState({
        isLoading: false,
        isValid: false,
        isIE: true
      })
      return;
    }

    const customerId = new URL(window.location.href).searchParams.get('p') || 'id';
    const apiEndpoint = process.env.REACT_APP_API_FETCH_CUSTOMER_DATA || '';
    const apiParams = {
      p: customerId
    }
    axios
      .get(apiEndpoint, {params:apiParams})
      .then(res => {
        this.setState({
          isLoading: false,
          id: customerId
        })
        res.data.status === 1 || res.data.status === 2 ? this.setState({isValid: true}) : this.setState({isValid: false})
        res.data.status === 2 ? this.setState({isAnswered: true}) : this.setState({isAnswered: false})
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <Loading isVisible={this.state.isLoading} />
          <header>
            <img className="logo_header_au" src={`${process.env.PUBLIC_URL}/static/img/logo_header_au.gif`} alt="logo_header_au" />
            <img className="logo_header_uq" src={`${process.env.PUBLIC_URL}/static/img/logo_header_uq.png`} alt="logo_header_uq" />
            <img className="logo_header_povo" src={`${process.env.PUBLIC_URL}/static/img/logo_header_povo.png`} alt="logo_header_povo" />
          </header>
          <Box m="1rem">
            {this.state.isValid &&
              <BrowserRouter>
                <Switch>
                  <Route 
                    exact 
                    path="/" 
                    render={props => <Top isAnswered={this.state.isAnswered} {...props} />} 
                  />
                  <Route 
                    exact 
                    path="/Questionnaire" 
                    render={props => <Questionnaire id={this.state.id} {...props} />}
                  />
                  <Route 
                    exact 
                    path="/Complete" 
                    component={Complete} 
                  />
                  <Route
                    component={Invalid}
                  />
                </Switch>
              </BrowserRouter>
            }
            {this.state.isValid === false && !this.state.isIE &&
              <Invalid invalidMessage="URLの有効期限が切れています。" />
            }
            {this.state.isValid === false && this.state.isIE &&
              <Invalid invalidMessage="別ブラウザで表示してください。" />
            }
          </Box>
          <footer>

            <Box m=".5rem">
              <Typography variant="caption">
                <a href='https://www.kddi.com/terms/requirements/'>動作環境・Cookie情報の利用について</a><br />
              </Typography>
            </Box>
            <img src={`${process.env.PUBLIC_URL}/static/img/logo_footer.gif`} alt="logo_footer" />
            <Box m=".5rem">
              <Typography variant="caption">
                COPYRIGHT © KDDI CORPORATION, ALL RIGHTS RESERVED.
              </Typography>
            </Box>
          </footer>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;