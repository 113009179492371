import React from 'react';
import axios from 'axios';
import * as H from 'history';
import Loading from './Loading';
import {
  Typography,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button
} from '@material-ui/core';
import './style.css';

interface Props {
  id: string,
  history: H.History
}

interface State {
  questionMainValue: string,
  questionSubValue: string,
  isDisabled: boolean,
  isLoading: boolean,
}

const SubRadio: React.FC<{radioData:{value:string; label:string}[]; handleChange:any}> = ({radioData, handleChange}) => { 
  return (
    <>
      <RadioGroup 
        name="question_sub"
        onChange={(e)=>handleChange(e.target.name, e.target.value)}
      >
        {radioData.map((data, index) => (
          <Box className="question_sub_radio" key={index}>
            <FormControlLabel 
              value={data.value}
              label={<Typography variant="body2">{data.label}</Typography>}
              control={<Radio color="primary" size="small" />}
            />
          </Box>
        ))}
      </RadioGroup>
    </>
  )
} 

class Questionnaire extends React.Component<Props, State> { 
  state: State = {
    questionMainValue: '',
    questionSubValue: '',
    isDisabled: true,
    isLoading: false,
  };

  handleChange = (name:string, value:string) => {
    if (name === 'question_main') {
      this.setState({questionMainValue: value})
      this.setState({questionSubValue: ''})
      this.setState({isDisabled: true})
    } else {
      this.setState({questionSubValue: value})
      this.setState({isDisabled: false})
    }
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ isLoading: true })
    const apiEndpoint = process.env.REACT_APP_API_REGISTER_QUESTIONNAIRE_RESULT || '';
    const apiParams = {
      id: this.props.id,
      questionMainValue: this.state.questionMainValue,
      questionSubValue: this.state.questionSubValue
    }
    axios
      .post(apiEndpoint, JSON.stringify(apiParams))
      .then(res => {
        this.setState({ isLoading: false })
        sessionStorage.setItem('id', this.props.id)
        sessionStorage.setItem('isAnswered', 'done')
        this.props.history.replace('/Complete')
      })
      .catch(err => {
        console.log(err)
      })
  };

  render() {
    const radioData = [
      { value: "service", label: "あんしんフィルター for au以外を設定済み" },
      { value: "reason", label: "フィルタリングサービスは利用しない" }
    ]
    const serviceList = [
      { value: "1", label: "スクリーンタイム（iOS）" },
      { value: "2", label: "ファミリーリンク（Android）" },
      { value: "3", label: "その他のフィルタリングサービス" }
    ]
    const reasonList = [
      { value: "1", label: "本人が仕事をしており、利用すると支障が出るため" },
      { value: "2", label: "本人の障がい、病気等により、利用すると支障が出るため" },
      { value: "3", label: "保護者が利用状況を把握し、安全にインターネットを利用させるため" },
      { value: "4", label: "本人が成人になったため" }
    ]

    return (
      <>
        <Loading isVisible={this.state.isLoading} />
        <Typography variant="h6" gutterBottom>フィルタリングサービスのご利用状況をお選びください</Typography>
        <Divider />
        <Box component="form" onSubmit={this.handleSubmit} m="1rem auto">
          <FormControl fullWidth>
            <RadioGroup 
              name="question_main"
              onChange={(e)=>this.handleChange(e.target.name, e.target.value)}
            >
              {radioData.map((data, index) => (
                <Box key={index}>
                  <Box className="question_main_radio" bgcolor="#e6e6e6" borderRadius="10px" m=".5rem 0" p="1rem 0">
                    <FormControlLabel 
                      value={data.value}
                      label={<Typography variant="body1">{data.label}</Typography>}
                      control={<Radio color="primary" />}
                    />
                  </Box>
                  {data.value === this.state.questionMainValue && data.value === "service" &&
                    <Box m="1rem .2rem">
                      <Typography variant="body2" paragraph>
                        設定しているフィルタリングサービスをお選びください。（必須）
                      </Typography>
                      <SubRadio radioData={serviceList} handleChange={this.handleChange} />
                    </Box>
                  }
                  {data.value === this.state.questionMainValue && data.value === "reason" &&
                    <Box m="1rem .2rem">
                      <Typography variant="body2">
                        以下より理由を選択してください。（必須）
                      </Typography>
                      <Typography variant="body2" className="text_emphasis" paragraph>
                        ※法令によりフィルタリングを利用しない場合は申告が必要です。
                      </Typography>
                      <SubRadio radioData={reasonList} handleChange={this.handleChange} />
                    </Box>
                  }
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
          <Box textAlign="center" m="2rem auto">
            <Typography variant="body1">すべての情報を入力したら、<br />「回答を送信」してください。</Typography>
          </Box>
          <Box textAlign="center" m="2.5rem">
            <Button type="submit" variant="contained" color="primary" disabled={this.state.isDisabled}>
              回答を送信
            </Button>
          </Box>
        </Box>
      </>
    );
  }
}

export default Questionnaire;