import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Divider,
  Box,
  Button,
} from '@material-ui/core'

interface Props {
  isAnswered?: boolean;
}

class Top extends React.Component<Props, {}> {
  render() {
    const isAnswered = sessionStorage.getItem('isAnswered')
    let componentName = this.props.isAnswered ? '/Complete' : '/Questionnaire';
    if(isAnswered === 'done') {
      componentName = '/Complete';
    }
    return (
      <>
        <Typography variant="h6">フィルタリングサービス</Typography>
        <Typography variant="h6" gutterBottom>ご利用状況の確認</Typography>
        <Divider />
        <Box m="1rem auto">
          <Typography variant="body1" paragraph>
            現在のお客さまのフィルタリングサービスのご利用状況について回答をお願いします。
          </Typography>
          <Typography variant="body1" paragraph>
            ※あんしんフィルター for auの設定または本アンケートフォームへのご回答が確認できない場合、今後も継続してご確認のメールをお送りいたしますのであらかじめご了承ください。
          </Typography>
        </Box>
        <Box textAlign="center" m="2.5rem">
          <Button variant="contained" color="primary" component={Link} to={componentName}>
            回答する
          </Button>
        </Box>
      </>
    );
  }
}

export default Top;